/* hiding the advertisment on mobile since it seems to display the label "advertisement" even when no ad is flighted -- EB 7.21.17*/
@media (max-width:1024px) {
  /*accounting for phone & tablet */
  .mobile_adlabelleft {
    display: none !important;
  }
}

.AdUnit {
  background-image: none !important;
}

#square {
  margin-top: 50px;
  margin-bottom: 30px;
}

/* ----- 2015 CME Now redesign ----- */

/* filter */

#edu .bucket#filter {
  margin: 0;
  max-width: 99%;
}

#edu .bucket#filter .bucketHeader {
  padding: 16px 0px 14px 0px;
  overflow: hidden;
}

#edu .bucket#filter .bucketHeader h2 {
  font-family: 'proxima_nova_ltlight', arial, sans-serif;
  font-weight: normal;
  font-size: 36px;
  text-transform: none;
  color: #333132;
  float: left;
  -webkit-margin-after: 0px;
  margin: 0px;
  margin-top: 0px;
}

#edu .bucket#filter .bucketHeader h2 .tinyTitle {
  padding-bottom: 2px;
  text-transform: uppercase;
  font-size: 16px;
  display: block;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-stretch: condensed;
  letter-spacing: 0.8px;
  color: #333132;
}

#edu .bucket#filter .bucketHeader #refineButton {
  text-align: center;
  clear: both;
  margin-top: -38px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 16px;
  color: #fff;
  float: right;
  display: inline-block;
  width: 70px;
  height: 40px;
  line-height: 40px;
  background-color: #005b81;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  background-image: url(/pi/global/icons/filter-icon.svg);
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 22px 19px;
  padding-right: 30px;
}

#edu .bucket#filter .bucketHeader #refineButton:hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  background-image: url(/pi/global/icons/filter-icon.svg);
  /*background-color:#007cb0;*/
}

#edu .bucket#filter .bucketHeader #refineButton.closed {
  background-color: #007cb0;
}

#edu .bucket#filter .bucketHeader #refineButton.closed:hover {
  background-color: #005b81;
}

#edu .bucket#filter .bucketContent {
  border-top: 2px solid #cfd7dd;
  overflow: hidden;
  display: none;
}

#edu .bucket#filter .filterCols {
  margin: 11px 12px 11px 10px;
}

#edu .bucket#filter .filterCols p.instructions {
  margin: 0;
  padding: 0 0 12px 0;
  font-style: italic;
  font-size: 0.80em;
  color: #666;
}

#edu .bucket#filter .filterCols .filterCol {
  margin: 0 15px 12px 0;
  float: left;
}

#edu .bucket#filter .filterCols .filterCol#topics {
  margin-right: 0px;
  width: 240px;
  position: relative;
}

#edu .bucket#filter .filterCols .filterCol#credits {
  min-width: 120px;
  margin-right: 0px;
}

#edu .bucket#filter .filterCols .filterCol#format {
  min-width: 115px;
  margin-right: 0px;
}

#edu .bucket#filter .filterCols .filterCol#type {
  margin-right: 0;
  min-width: 125px;
}

#edu .bucket#filter .filterCols .filterCol h4 {
  margin: 0;
  padding: 0 0 12px 0;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-stretch: condensed;
  letter-spacing: 0.8px;
  color: #333132;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
}

#edu .bucket#filter .filterCols .filterCol ul {
  margin: 0;
  padding: 0 0 0 1px;
  list-style: none;
}

#edu .bucket#filter .filterCols .filterCol ul li {
  margin: 0;
  padding: 0 0 8px 0;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 0.9em;
}

#edu .bucket#filter .filterCols .filterCol#topics ul {
  height: 180px;
  overflow-y: scroll;
}

#edu .bucket#filter .filterCols .filterCol#topics ul li {
  padding-right: 6px;
}

#edu .bucket#filter .filterCols .filterCol ul li,
#edu .bucket#filter .filterCols .filterCol ul li label {
  cursor: pointer;
}

.filterCol#topics ::-webkit-scrollbar {
  width: 10px;
}

.filterCol#topics ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.filterCol#topics ::-webkit-scrollbar-thumb {
  height: 50px;
  width: 10px;
  background: #007cb0;
  border: 0px none #fff;
  border-radius: 0px;
}

.filterCol#topics ::-webkit-scrollbar-thumb:hover {
  background: #007cb0;
}

.filterCol#topics ::-webkit-scrollbar-thumb:active {
  background: #005b81;
}

.filterCol#topics ::-webkit-scrollbar-track {
  background: #e5e5e5;
  border: 0px none #fff;
  border-radius: 0px;
}

.filterCol#topics ::-webkit-scrollbar-track:hover {
  background: #e5e5e5;
}

.filterCol#topics ::-webkit-scrollbar-track:active {
  background: #ececec;
}

.filterCol#topics ::-webkit-scrollbar-corner {
  background: transparent;
}

#edu .bucket#filter .filterCol#topics #topicsFade {
  display: none;
}

#edu .bucket#filter input[type="checkbox"] {
  display: none;
}

#edu .bucket#filter input[type="checkbox"]+label {
  margin: 0;
  padding: 1px 0 0 24px;
  background-image: url("//img.medscape.com/pi/cme/mycme/form-element-checkbox.png");
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  min-height: 16px;
  display: block;
}

#edu .bucket#filter input[type="checkbox"]:checked+label {
  background-image: url("//img.medscape.com/pi/cme/mycme/form-element-checkbox-checked.png");
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi) {
  #edu .bucket#filter input[type="checkbox"]+label {
    background-image: url("//img.medscape.com/pi/cme/mycme/form-element-checkbox-2x.png");
    background-size: 16px 16px;
  }
  #edu .bucket#filter input[type="checkbox"]:checked+label {
    background-image: url("//img.medscape.com/pi/cme/mycme/form-element-checkbox-checked-2x.png");
    background-size: 16px 16px;
  }
}

#edu .bucket#filter .filterButtons {
  margin: 0 12px 12px 0;
  float: right;
}

#edu .bucket#filter .filterButtons .cancel {
  display: none;
}

#edu .bucket#filter .filterButtons input[type="button"],
#edu .bucket#filter .filterButtons input[type="submit"] {
  margin: 0 0 0 6px;
  padding: 8px 25px 7px 25px;
  font-family: 'proxima_nova_ltsemibold', arial, sans-serif;
  font-size: 1.1em;
  font-weight: normal; -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  background: transparent;
  border: 1px solid #007cb0;
  font-family: 'proxima_nova_rgregular';
  font-size: 16px;
  color: #007cb0;
}

#edu .bucket#filter .filterButtons input[type="button"].apply {
  background-color: #b9bfc3;
  color: #fff;
  border: #b9bfc3;
}

#edu .bucket#filter .filterButtons input[type="button"].cancel {
  padding: 6px 25px 6px 25px;
}

#edu .bucket#filter .filterButtons input[type="button"].cancel:hover {
  color: #fff;
  background-color: #005b81;
}

#edu .bucket#filter .filterButtons input[type="button"].apply.enabled,
#edu .bucket#filter .filterButtons input[type="submit"].apply.enabled {
  color: #fff;
  background: #007cb0;
  border: none;
}

#edu .bucket#filter .filterButtons input[type="button"]:hover {
  cursor: pointer;
}

#edu .bucket#filter .filterButtons input[type="button"].apply.enabled:hover {
  background-color: #005b81;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.filterCol#topics ::-webkit-scrollbar-thumb {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 16px rgba(192, 192, 192, 0.9);
  /*background:url("//img.qa01.medscapestatic.com/pi/global/icons/scroll-bg.png"); */
  background-size: 300% auto;
  border-left: 0px solid #E5E5E5;
  border-right: 0px solid #E5E5E5;
  border-bottom: 0px solid #E5E5E5;
  border-top: 0px solid #E5E5E5;
  border-radius: 0%;
}

.filterCol#topics ::-webkit-scrollbar {
  width: 12px;
  height: 7px;
  background-color: #ffffff;
}

.filterCol#topics ::-webkit-scrollbar-button:start:decrement,
.filterCol#topics ::-webkit-scrollbar-button:end:increment {
  display: block;
}

.filterCol#topics ::-webkit-scrollbar-button:vertical:start:increment,
.filterCol#topics ::-webkit-scrollbar-button:vertical:end:decrement {
  display: none;
}

.filterCol#topics ::-webkit-scrollbar-button:end:increment {
  background-image: url('ARROW IMAGE URL HERE.PNG'); background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: inset 0 0 16px rgba(214, 214, 214, 0.9);
}

.filterCol#topics ::-webkit-scrollbar-button:start:decrement {
  background-image: url('ARROW IMAGE URL HERE.PNG');
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: inset 0 0 16px rgba(214, 214, 214, 0.9);
}

.filterCol#topics ::-webkit-scrollbar-thumb {
  background-color: #007cb0;
  border-left: 0px solid #E5E5E5;
  border-right: 0px solid #E5E5E5;
  border-bottom: 0px solid #E5E5E5;
  border-top: 0px solid #E5E5E5;
  border-radius: 20px;
}

.filterCol#topics ::-webkit-scrollbar-track {
  border-radius: 0%;
}

body,
#bodypadding {
  width: auto;
}

#bodypadding {
  background-color: #E2EAEE;
}

#resource-center {
  border-bottom: 4px solid #701104;
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

#resource-center a {
  text-decoration: none;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 14px;
  color: #2e4154;
  margin-right: 15px;
  vertical-align: middle;
  transition: color 0.1s linear;
}

#resource-center a:hover {
  color: #a6afb7;
  transition: color 0.1s linear;
}

#resource-center .resource-center-view-all a {
  color: #007cb0;
  float: right;
  font-weight: 600;
  transition: color 0.3s linear;
}

#resource-center .resource-center-view-all svg {
  margin-bottom: -3px;
  margin-left: 5px;
}

#resource-center .resource-center-view-all:hover a {
  color: #005b81;
  transition: color 0.3s linear;
}

#resource-center .max-width-limiter {
  text-align: left;
  width: 1280px;
  display: inline-block;
}

#edu {
  max-width: 1280px;
  margin: auto;
}

#left {
  width: 880px;
  padding-right: 40px;
  display: inline-block;
  float: left;
}

#right {
  max-width: 322px;
  display: inline-block;
  float: right;
}

.spacer {
  clear: both;
}

#saved-activities {
  margin-top: 22px;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 16px;
  color: #222;
  border-bottom: solid 4px rgba(102, 33, 36, 0.22);
  padding-bottom: 44px;
  display: none;
  max-width: 99%;
  min-width: 99%;
}

#saved-activities.active {
  display: inline-block;
}

.saved-activities-header {
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 18px;
  height: 21px;
  letter-spacing: 0.8px;
  color: #333132;
  font-stretch: condensed;
  text-transform: uppercase;
}

.saved-activities-header .saved-activities-number {
  font-size: 20px;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-weight: 600;
  margin-left: 14px;
}

.saved-activities-number {
  text-transform: initial;
  color: #007cb0;
}

#saved-activities .saved-activities-number a {
  color: #007cb0;
}

.saved-activities-number a {
  float: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.93;
  text-align: center;
  color: #007cb0;
}

.saved-activities-center {
  text-align: center;
  margin-top: 18px;
  width: 100%;
}

.saved-activities-cta {
  font-size: 34px;
  color: #333132;
  display: block;
  margin-bottom: 8px;
}

.saved-card .activityImage {
  display: none;
}

.saved-card .teaser {
  display: none;
}

.saved-ul.cards {
  width: 100%;
}

.refine-topics-search {
  margin-bottom: 15px;
  width: 223px;
  padding: 7px;
  height: 25px; -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
}

.dynamic-column-1-header,
.dynamic-column-2-header,
.dynamic-column-3-header {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.cards {
  width: 1000px;
  list-style: none;
  padding: 0px 10px 0 0;
  float: left;
  margin-top: 0px;
}

.cards li {
  padding: 14px;
  margin: 14px 2px;
  background-color: #fafafa;
  box-shadow: 0 2px 3px 0 rgba(95, 99, 102, 0.4);
  width: 254px;
}

.cards img {
  max-width: 254px;
}

.view-all-arrow {
  background-size: 6px auto;
  background-image: url(/pi/global/icons/icon-arrow-blue-right.png);
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: 0px 3px;
  float: right;
  display: block;
  margin-top: 5px;
  margin-left: 5px;
}

.small-img .teaser img {
  max-width: 80px;
  margin-left: 5px;
  float: right;
  display: none;
}

.card {
  opacity: 0;
  margin-top: 140px;
  transition: all 0.0s linear;
}

.card.ready-two {
  opacity: 1;
  margin-top: 0px;
  transition: all 0.1s linear;
}

.card.ready {
  opacity: 1;
  margin-top: 0px;
  transition: all 0.5s linear
}

.card.loading img {
  visibility: hidden;
}

.cards .activityTitle a {
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 20px;
  text-decoration: none;
  line-height: 1.1;
  color: #333132;
}

.cards .byline {
  font-family: 'proxima_nova_rgregular';
  color: #999;
  font-size: 12px;
  margin-top: 7px;
}

.cards .teaser {
  font-family: 'proxima_nova_rgregular';
  color: #222;
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 10px;
}

.cards .activityImage.small {}

#activities .bucketHeader h2 {
  display: none;
}

.star-red-filled {
  display: inline-block;
  background-image: url(/pi/global/icons/star-red-filled.png);
  background-size: 18px;
  width: 18px;
  height: 18px;
  background-position: 0px 2px;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.activityData {
  margin-top: 15px;
}

/*
.credits {
	display:inline-block;
	text-align:center;
	font-family: 'Roboto Condensed', arial, sans-serif;
	font-size:13px;
}
*/

.abim-credits,
.primary-credits,
.length {
  display: inline-block;
  text-align: center;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 13px;
  margin-left: 0px;
}

.length {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 13px;
  margin-left: 0px;
  /* border-right: solid 1px rgba(47, 32, 72, 0.33); */
}

.credits-number {
  font-size: 18px;
}

.saveState {
  min-width: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #01808d;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 14px;
}

.star-blue {
  display: inline-block;
  background-image: url(/pi/global/icons/star-blue-hollow.png);
  background-size: 18px;
  width: 18px;
  height: 18px;
  background-position: 0px 2px;
  background-repeat: no-repeat;
  transition: all 0.1s linear;
  margin-bottom: 3px;
}

.saveState:hover .star-blue {
  display: inline-block;
  background-image: url(/pi/global/icons/star-blue-filled.png);
  background-size: 18px;
  width: 18px;
  height: 18px;
  background-position: 0px 2px;
}

.saved .star-blue {
  display: inline-block;
  background-image: url(/pi/global/icons/star-blue-filled.png);
  background-size: 18px;
  width: 18px;
  height: 18px;
  background-position: 0px 2px;
  background-repeat: no-repeat;
  /*margin-top:5px;*/
}

.saveState.saved:hover .star-blue {
  display: inline-block;
  background-image: url(/pi/global/icons/star-blue-filled.png);
  background-size: 18px;
  width: 18px;
  height: 18px;
  background-position: 0px 2px;
}

.saveState {
  display: inline-block;
  float: right;
  cursor: pointer;
  text-align: center;
  margin-right: 3px;
}

#tracker {
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  width: 270px;
  padding: 20px;
  padding-top: 25px;
  text-align: center;
  letter-spacing: 1px;
}

#tracker .logged-out-tracker {
  font-family: 'proxima_nova_rgregular';
  text-transform: none;
  font-size: 16px;
  letter-spacing: initial;
  margin: 30px 0px;
}

#tracker .logged-out-tracker a {
  color: #007cb0;
  text-decoration: none;
}

#tracker .bucketHeader {
  margin-bottom: 22px;
}

#tracker .morelink {
  margin-top: 28px;
  display: inline-block;
  width: 162px;
  height: 41px;
  border-radius: 3px;
  background-color: #007cb0;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}

#tracker .morelink:hover {
  background-color: #005b81;
}

#tracker .morelink a {
  line-height: 41px;
  font-family: 'proxima_nova_rgregular';
  color: #fff;
  text-transform: none;
  text-decoration: none;
  font-size: 16px;
}

.tracker-ribbon {
  display: inline-block;
  background-image: url(/pi/global/icons/activity-tracker-ribbon.png);
  background-size: 15px 21px;
  width: 15px;
  height: 21px;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  margin-bottom: -5px;
  margin-right: 5px;
}

.cards.pre-extra-wide {
  margin-bottom: 0px;
}

.cards.extra-wide-article {
  padding-top: 0px;
  margin-top: 2px;
  padding-bottom: 0px;
}

.cards.extra-wide-article .activityImage {
  display: none;
}

.cards.extra-wide-article .activityTitle .activityImage {
  display: block;
}

#featuredTopics,
#belowTrackerSection,
#eventCalloutsRight,
.multispecialty-hp {
  border-radius: 6px;
  background-color: rgba(250, 250, 250, 0.62);
  width: 270px;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 16px;
  padding: 25px 17px 20px 15px;
  margin-bottom: 40px;
  color: #000;
}

#featuredTopics h3,
#belowTrackerSection h3,
#eventCalloutsRight h3,
.multispecialty-hp h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 0.79;
  color: #333132;
  letter-spacing: 0.2px;
  font-family: "proxima_nova_rgbold", arial, sans-serif;
  text-transform: uppercase;
  margin-top: 0px;
}

.directedLearning .collectionItem {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}
.directedLearning .prgteaser {
    margin-left: 4px;
}

#eventCalloutsRight h3 {
  margin-bottom: 16px;
  display: block;
  margin-left: -3px;
}

#featuredTopics h3 {}

#featuredTopics ul {
  list-style-type: none;
  -webkit-padding-start: 0px;
  padding-left: 0px;
}

#featuredTopics ul li {
  display: none;
  margin-top: 22px;
  padding-bottom: 22px;
  border-bottom: solid 1px #d8d8d8;
  min-height: 46px;
}

#featuredTopics ul li a {
  text-decoration: none;
  font-stretch: condensed;
  color: #000;
  line-height: 1.13;
}

#featuredTopics ul li img {
  float: right;
  width: auto;
  height: 46px;
  margin-left: 5px;
}

#featuredTopics ul li:nth-child(-n+6) {
  display: block;
}

.multispecialty-hp ul {
  list-style-type: none;
  -webkit-padding-start: 0px;
  padding-left: 0px;
}

.multispecialty-hp ul li {
  margin-top: 5px;
  padding-bottom: 5px;
  min-height: 20px;
}

.multispecialty-hp ul li a {
  text-decoration: none;
  font-stretch: condensed;
  color: #000;
  line-height: 1.13;
}

#staff h4 {
  -webkit-margin-after: 16px;
  letter-spacing: 0.2px;
  font-family: "proxima_nova_rgbold", arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #333132;
  text-transform: uppercase;
}

#staff ul {
  list-style-type: none;
  -webkit-padding-start: 0px;
  margin-bottom: 40px;
  padding-left: 0px;
  margin-top: 6px;
}

#staff li a {
  font-family: 'proxima_nova_rgregular';
  color: #007cb0;
  font-size: 18px;
  text-decoration: none;
  line-height: 1.63;
}

#staff ul li {
  margin-bottom: 0px;
  display: block;
  font-family: 'proxima_nova_rgregular';
  font-size: 18px;
  text-decoration: none;
  line-height: 1.63;
}

#pre-footer {
  width: 100%;
  text-align: center;
}

#pre-footer .bucketFooter {
  padding: 25px 0;
  font-family: 'proxima_nova_ltsemibold', arial, sans-serif;
  line-height: 1;
  font-size: 1.2em;
  text-align: center;
}

#pre-footer ul.pageNumbers {
  margin: 0;
  padding: 0;
  list-style: none;
}

#pre-footer ul.pageNumbers li {
  margin: 0 25px;
  display: inline;
}

#pre-footer ul.pageNumbers li a {
  color: #007cb0;
  text-decoration: none;
  font-family: 'proxima_nova_ltsemibold';
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

#pre-footer ul.pageNumbers li.activePage {
  color: #000;
  text-decoration: none;
  font-family: 'proxima_nova_ltsemibold';
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

#pre-footer ul.pageNumbers li.pageBack a,
#pre-footer ul.pageNumbers li.pageForward a {
  width: 36px;
  height: 32px;
  background-color: #007cb0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

#pre-footer ul.pageNumbers li.pageBack a {
  background-image: url("//img.medscape.com/pi/cme/mycme/icon-arrow-white-page-left.png");
  background-position: 12px 7px;
  background-repeat: no-repeat;
}

#pre-footer ul.pageNumbers li.pageForward a {
  background-image: url("//img.medscape.com/pi/cme/mycme/icon-arrow-white-page-right.png");
  background-position: 14px 7px;
  background-repeat: no-repeat;
}

#pre-footer ul.pageNumbers li.pageBack a:hover,
#pre-footer ul.pageNumbers li.pageForward a:hover {
  background-color: #005b81;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#pre-footer ul.pageNumbers li.hiddenPageNum {
  display: none;
}

/* no results */

#edu.mycme #noResults {
  padding: 25px 10px 20px 10px;
  background: #fff;
}

#edu.mycme #noResults p#noResultsMsg {
  padding: 0 0 10px 0;
  font-family: 'proxima_nova_ltlight', arial, sans-serif;
  font-size: 2.2em;
}

#edu.mycme #noResults p#noResultsSelections {
  padding: 0 0 15px 0;
  font-style: italic;
  font-size: 1.5em;
  color: #005b81;
}

#edu.mycme #noResults p#noResultsTryAgain {
  padding: 0;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 1.4em;
}

.total-results-refine-box {
  float: left;
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-stretch: condensed;
  letter-spacing: 0.8px;
  color: #333132;
  font-size: 21px;
  margin-right: 15px;
  line-height: 37px;
}

.animated-checkbox {
  background-image: url(/pi/global/icons/animated-checkbox.png);
  background-color: #fff;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: 0px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  vertical-align: -3px;
  border: 1px solid #abbfca;
}

.animated-checkbox:hover {
  border: solid 1px #007cb0;
}

.animated-checkbox.unchecked {
  background-color: #fff;
  background-size: 16px 144px;
  animation: uncheckanimate 0.1s steps(4) 1 forwards;
}

.filtering .animated-checkbox.unchecked {
  animation: none;
}

.animated-checkbox.checked {
  background-color: #007cb0;
  background-size: 16px 144px;
  animation: checkanimate 0.1s steps(4) 1 forwards;
}

@keyframes checkanimate {
  0% {
    background-color: #fff;
  }
  100% {
    background-position: 0px -64px;
    background-color: #007cb0;
  }
}

@keyframes uncheckanimate {
  0% {
    background-color: #007cb0;
    background-position: 0px -64px;
  }
  100% {
    background-position: 0px -128px;
    background-color: #fff;
  }
}

#edu .bucket#filter .filterCols .filterCol ul li label {
  margin-left: 5px;
}

.total-results-refine-box.initial-hide {
  display: none;
}

#edu .bucket#filter .filterButtons input[type="button"].lock-button,
#edu .bucket#filter .filterButtons input[type="submit"].lock-button,
#edu .bucket#filter .filterButtons input[type="button"].apply.enabled.lock-button,
#edu .bucket#filter .filterButtons input[type="submit"].apply.enabled.lock-button {
  animation: delayedlock 1s 1 forwards;
  animation-delay: 1s;
  cursor: wait;
}

#edu .bucket#filter .filterButtons input[type="button"].cancel.lock-button {
  animation: backgroundadjust 1s 1 forwards;
  animation-delay: 1s;
}

@keyframes delayedlock {
  100% {
    background-color: #dae0e4;
    color: #4e4f4f;
    outline: none;
    transition: 2s all linear;
  }
}

@keyframes backgroundadjust {
  100% {
    background-color: #dae0e4;
    color: #4e4f4f;
    outline: none;
    transition: 2s all linear;
    border: solid 1px #dae0e4;
  }
}

#refineOptionsSelected {
  display: none;
}

.homepage-button {
  transition: all 0.2s linear;
}

.homepage-button.apply {
  border: solid 1px transparent;
}

#filterContent {
  /*background-color: transparent;*/
  background-color: #fafafa;
  margin-bottom: 5px;
}

.meta-info {
  display: none;
}

.posting-date {
  display: none;
}

.activityData {
  text-align: center;
}

.cards.masonry {
  display: flex;
}

.dynamic-column-1-header,
.dynamic-column-2-header,
.dynamic-column-3-header {
  display: flex;
  flex-flow: column
}

.card {
  order: 2;
}

/*
.featured-1 {
	display:block !important;
	order:1;
}
*/

.activityData {
  display: flex;
  justify-content: space-around;
}

.activity-data-divider {
  background-image: url('/pi/global/icons/icon-dotted-divider.png');
  background-size: 100%;
  width: 1px;
}

.hidden {
  display: none !important;
}

.displayed {
  display: block;
}

#edu .bucket#filter .filterCols .filterCol ul li {
  text-indent: -21px;
  margin-left: 21px;
}

.filterColumns {
  display: flex;
  justify-content: space-between;
}

.jsp-featured {
  border-top: 4px solid #72252b;
}

.extra-wide-article li:before {
  content: "PICKED FOR YOU";
  margin-bottom: 10px;
  display: block;
  margin-top: -3px;
  line-height: 1.19;
  letter-spacing: 0.8px;
  color: #333132;
  font-family: 'Roboto Condensed', arial, sans-serif;
}

#featuredTopics ul.multispecialty li {
  border-bottom: none;
  padding-bottom: 0px;
  display: block;
  min-height: 5px
}

#featuredTopics .bucketFooter .viewmore,
#eventCalloutsRight .bucketFooter .expandLink {
  padding-left: 12px;
  display: block;
  cursor: pointer;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-weight: 600;
  color: #007cb0;
  background-image: url(/pi/global/icons/icon-arrow-blue-refine-down.png);
  background-size: 10px;
  background-position: 0px center;
  background-repeat: no-repeat;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
}

#featuredTopics .bucketFooter .viewmore:hover,
#eventCalloutsRight .bucketFooter .expandLink:hover {
  text-decoration: underline;
}

#featuredTopics .bucketFooter .viewmore:after {
  content: "More";
}

#eventCalloutsRight .bucketFooter .expandLink:after {
  margin-left: 5px;
  content: "More";
}

#featuredTopics.expanded .bucketFooter .viewmore,
#eventCalloutsRight.expanded .bucketFooter .expandLink {
  background-image: url(/pi/global/icons/icon-arrow-blue-refine-up.png);
}

#featuredTopics.expanded .bucketFooter .viewmore:after {
  content: "Less";
}

#eventCalloutsRight.expanded .bucketFooter .expandLink:after {
  content: "Less";
}

#featuredTopics.expanded ul li {
  display: block;
}

.activityImage {
  text-align: center;
}

#content-wrap {
  display: flex;
  justify-content: space-between;
}

.teaser img,
.teaser a {
  display: inline;
}

.filtered-selections {
  color: #333132;
  background-image: url(/pi/global/icons/filtered-selections-close.png);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: right 6px center;
  background-color: #c4d4db;
  padding: 0px 22px 0px 8px;
  cursor: pointer;
  margin-right: 5px;
  line-height: 32px;
  display: inline-block;
  margin-top: 3px;
  font-size: 13px;
}

.filtered-selections:hover {
  background-color: #b1c7d1;
}

.filtered-selections:after {
  content: " ";
}

.totals-by-type {
  display: flex;
  justify-content: space-around;
}

#tracker .credit-name {
  display: block;
}

.credits-by-type:nth-child(4) {
  display: none;
}

#tracker .credits-amount {
  font-size: 22px;
  line-height: 1.05;
  color: #333132;
  font-stretch: condensed;
}

#tracker .credit-name {
  font-size: 12px;
  font-stretch: condensed;
  line-height: 1.5;
  text-align: center;
  color: #707070;
}

#tracker .credits-by-type {
  border-right: dotted 1px;
  flex-grow: 1;
}

#tracker .credits-by-type:nth-child(3) {
  border-right: 0px;
  padding-right: 0px;
}

#tracker .credits-by-type:last-child {
  border-right: 0px;
  padding-right: 0px;
}

.completed-activity-wrapper {
  text-align: center;
}

.completed-activity {
  font-family: 'Roboto Condensed', arial, sans-serif;
  color: #46a37e;
  background-image: url(/pi/global/icons/icon-complete-check.png);
  background-size: 21px;
  background-repeat: no-repeat;
  background-position: left 5px center;
  padding: 3px 8px 3px 30px;
  margin-right: 5px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 16px;
  text-transform: uppercase;
}

.ous-section {
  border-bottom: solid 4px #d2beb2;
  margin-bottom: 15px;
}

.show-more-ous-wrapper {
  width: 100%;
  text-align: center;
}

.show-more-ous {
  color: #007cb0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  border: solid 1px #007cb0;
  padding: 7px 22px 7px 10px;
  margin-bottom: 20px;
  display: inline-block;
  cursor: pointer;
  background-image: url(/pi/global/icons/icon-arrow-blue-right.png);
  background-size: 7px;
  background-position: right 6px center;
  background-repeat: no-repeat;
}

.ous-title {
  font-family: 'Roboto Condensed', arial, sans-serif;
  font-size: 16px;
  font-stretch: condensed;
  letter-spacing: 0.8px;
  text-align: left;
  color: #333132;
  margin-top: 0px;
  display: block;
  clear: both;
  margin-bottom: 11px;
}

.no-results-found {
  margin-top: 30px;
  display: block;
  font-size: 24px;
}

.resource-center-items a {
  text-decoration: none;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 14px;
  color: #2e4154;
  margin-right: 15px;
  vertical-align: middle;
  transition: color 0.1s linear;
}

a.cme-submenu-more,
a.cme-submenu-more:visited {
  background-color: white;
  width: 100px;
  line-height: 35px;
  text-decoration: none;
  margin-top: 5px;
  color: #007cb0;
  float: right;
  font-weight: 600;
  margin-right: 0px;
  text-transform: none;
  text-align: center;
}

.header-cme-rclist {
  display: flex;
}

.header-cme-rclist .resource-center-items {
  width: calc(100% - 100px);
}

#edu .bucket#filter .bucketHeader h2 .tinyTitle {
  display: none !important;
}

#featuredTopics ul li.last-featured-topic {
  border-bottom: none;
  padding-bottom: 0px;
}

#featuredTopics .hidden-section {
  display: none;
}

.filter-select-options {
  display: none;
}

.error-saving-msg {
  color: red;
  margin-top: 5px;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.cards .card:hover {
  margin-top: -4px;
  margin-bottom: 18px;
  box-shadow: 0 4px 5px 1px rgba(95, 99, 102, 0.7)
}

.cards.extra-wide-article li {
  box-shadow: 0 2px 3px 0px rgba(95, 99, 102, 0.4);
  transition: all 0.1s linear;
}

.cards.extra-wide-article li:hover {
  margin-top: -4px;
  margin-bottom: 4px;
  box-shadow: 0 4px 5px 1px rgba(95, 99, 102, 0.7);
}

.header-cme-rclist {
  border-bottom: 4px solid #701104;
  background-color: #fff;
}

#pre-footer ul.pageNumbers li.page-hidden {
  display: none;
}

.activityData .length {
  color: #767674;
}

.hidden-data .activityData {
  display: none;
}

.full-width-row-location {
  display: none;
}

.extra-wide-article li {
  border-top: 4px solid #72252b;
  max-width: 844px;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
}

.extra-wide-article .activityData {
  float: left;
  width: 100%;
  max-width: 300px;
}

.extra-wide-article .activityImage {
  float: right;
}

.cards.masonry.pre-extra-wide {
  display: none;
}

.extra-wide-featured-row-two .cards.masonry.pre-extra-wide,
.extra-wide-featured-row-three .cards.masonry.pre-extra-wide {
  display: flex;
}

.extra-wide-featured-row-two .primary-card-section .dynamic-column-1.card:nth-child(-n+1),
.extra-wide-featured-row-two .primary-card-section .dynamic-column-2.card:nth-child(-n+1),
.extra-wide-featured-row-two .primary-card-section .dynamic-column-3.card:nth-child(-n+1) {
  display: none;
}

.extra-wide-featured-row-three .primary-card-section .dynamic-column-1.card:nth-child(-n+2),
.extra-wide-featured-row-three .primary-card-section .dynamic-column-2.card:nth-child(-n+2),
.extra-wide-featured-row-three .primary-card-section .dynamic-column-3.card:nth-child(-n+2) {
  display: none;
}

.extra-wide-featured-row-two .pre-extra-wide .dynamic-column-1.card:nth-child(n+2),
.extra-wide-featured-row-two .pre-extra-wide .dynamic-column-2.card:nth-child(n+2),
.extra-wide-featured-row-two .pre-extra-wide .dynamic-column-3.card:nth-child(n+2) {
  display: none;
}

.extra-wide-featured-row-three .pre-extra-wide .dynamic-column-1.card:nth-child(n+3),
.extra-wide-featured-row-three .pre-extra-wide .dynamic-column-2.card:nth-child(n+3),
.extra-wide-featured-row-three .pre-extra-wide .dynamic-column-3.card:nth-child(n+3) {
  display: none;
}

.outside-filter-box {
  margin-bottom: 5px;
}

.filter-top-section,
#modal-bg-layer {
  display: none;
}

.close-x {
  float: right;
  cursor: pointer;
  background-image: url('/pi/global/icons/icon-filter-x.png');
  width: 25px;
  height: 25px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 12px;
  padding-right: 25px;
}

.duplicate-article {
  display: none;
}

.jsp-ous-featured {
  border-top: 4px solid #72252b;
}

#left {
  margin-left: 10px;
}

#right {
  margin-right: 10px;
}

#mobile-filter-menu {
  display: none;
}

.activityData .credits-number {
  display: block;
}

#filter .bucketFooter {
  margin-bottom: 10px;
}

.saved-and-tracker {
  display: none;
}

.live-events {
  margin: 0 0 20px 0;
  padding: 0;
}

.live-events .bucketHeader {
  margin: 8px 8px 7px 8px;
  padding: 0;
  color: #535353;
}

.live-events .bucketHeader h3 {
  margin: 0;
  padding: 0 4px 0 0;
  font-size: 0.95em;
  font-weight: bold;
  text-transform: uppercase;
  display: inline;
}

.live-events .bucketContent {
  margin: 0;
  padding: 0;
}

.cmesitecallout {
  margin: 0 0 4px 0;
  padding: 4px 6px 4px 6px;
  font-size: 0.95em;
  border: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.live-events.less-than-two .cmesitecallout {
  border-bottom: none;
}

.live-events .event-header {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #2f2148;
  display: block;
  margin-bottom: 7px;
}

.live-events .event-title {
  color: #333132;
  font-size: 16px;
  text-transform: titlecase;
  font-weight: bold;
}

.live-events .register-link {
  display: block;
}

.live-events .byline {
  display: block;
  color: #767674;
  font-size: 14px;
  font-family: "proxima_nova_rgregular", sans-serif;
  margin: 14px 0px 5px 0px;
}

.live-events .byline .event-date {
  white-space: nowrap;
  background-image: url(/pi/global/icons/calendar-icon.svg);
  background-repeat: no-repeat;
  background-position: left 0px center;
  background-size: 18px;
  padding-left: 25px;
  display: block;
  margin-bottom: 5px;
  margin-right: 5px;
}

.live-events .byline .event-location {
  white-space: nowrap;
  background-image: url(/pi/global/icons/location-icon.svg);
  background-repeat: no-repeat;
  background-position: left 0px center;
  background-size: 15px;
  padding-left: 20px;
  display: inline-block;
  min-height: 18px;
}

.live-events .cmesitecallout {
  display: block;
}

.live-events .cmesitecallout:nth-child(n+2) {
  display: none;
}

.live-events.expanded .cmesitecallout:nth-child(n+2) {
  display: block;
}

.live-events .register-link a {
  color: #007cb0;
  font-size: 16px;
  font-family: "proxima_nova_ltsemibold", arial, sans-serif;
  margin-top: 16px;
  margin-bottom: 25px;
  display: block;
}

.live-events .toggle-view-less {
  display: none;
}

.live-events .toggle-view-more {
  display: block;
}

.live-events.active .toggle-view-less {
  display: block;
}

.live-events.active .toggle-view-more {
  display: none;
}

.live-events .expandLink {
  border-top: none;
}

.live-events {
  display: none;
}

.live-events.not-empty {
  display: block;
}

.live-events.less-than-two {
  border-bottom: none;
}

.live-events.less-than-two .bucketFooter {
  display: none;
}

/*********************************************/

/** Logged Out Banner **/

/*********************************************/

.hp-anon {
  display: block;
  background-image: linear-gradient(286deg, #d4deee, #e9e5eb);
}

.hp-anon .section-title .mscp {
  font-size: 0;
  width: 126px;
  height: 29px;
  /*background: transparent url(/pi/logos/mscp-logo.png?resize=252:*) no-repeat;*/
  background: transparent url(/pi/global/icons/med-logo-org-reg.png) no-repeat;
  background-size: 126px auto;
  display: inline-block;
}

.hp-anon .section-container {
  margin: 0 auto;
  padding: 0;
  max-width: 96%;
  width: 1240px;
  display: block;
  height: 200px;
}

.hp-anon.subscribe .section-container {
  min-height: 170px;
  height: auto;
}

.hp-anon .section-title {
  margin: 0;
  padding: 0;
  font-family: "proxima_nova_ltlight", sans-serif;
  font-size: 1.375rem;
  color: #000;
}

.hp-anon .section-title .title-text {
  padding: 0 0 1px 1px;
  vertical-align: bottom;
  display: inline-block;
}

.hp-anon p {
  padding: 0;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: #333132;
}

.hp-anon p.description {
  margin: 0;
  font-size: 28px;
  line-height: 2.125rem;
  width: 615px;
  margin-top: 4px;
}

.hp-anon p.login-line {
  margin: 0;
  padding: 0 0 22px;
  font-size: 1rem;
  line-height: 1.375rem;
  display: inline-block;
}

.hp-anon a.sign-up {
  margin: 12px 0 21px;
  padding: 12px 22px;
  background-color: transparent;
  background: #007cb0;
  font-family: "proxima_nova_ltsemibold", sans-serif;
  font-size: 1.25rem;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
}

.hp-anon a.sign-up:hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #005b81;
}

.hp-anon a.login-link {
  color: #007cb0;
}

.hp-anon .anon-graphic {
  background: url(/pi/global/icons/CME_Cards_tilted_large.png) right bottom no-repeat;
  background-size: auto 200px;
  box-shadow: inset 0px -7px 4px -4px rgba(0, 0, 0, 0.29);
}

.hp-anon.subscribe .anon-graphic {
  background: url(/pi/global/icons/PaperPlane_Large.png) right bottom no-repeat;
  background-size: auto 170px;
}

.hp-anon.logged-out {
  display: block;
}

.login-save-msg {
  transition: all 0.04s ease-out;
  clear: both;
}

#saved-activities a {
  color: #333132;
}

#saved-activities .card {
  text-align: left;
}

.saved-ul .dynamic-column-1:nth-child(2),
.saved-ul .dynamic-column-1:nth-child(3),
.saved-ul .dynamic-column-2:nth-child(2),
.saved-ul .dynamic-column-3:nth-child(3) {
  display: none;
}

.bt-item {
  display: block;
  margin-top: 20px;
}

.saved-ul .dynamic-column-1-header,
.saved-ul .dynamic-column-2-header,
.saved-ul .dynamic-column-3-header {
  width: 100%;
}

.login-save-msg {
  text-align: center;
  margin-top: 10px;
  color: red;
}

.hp-anon .login-line {
  margin-left: 16px;
}

.subscribe-wrapper {
  background-image: url(/pi/global/icons/org-hp-subscribe-large-left.png);
  background-repeat: no-repeat;
  min-height: 100px;
  background-size: auto 100px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #007cb2;
  margin-top: 10px;
}

.subscribe-header {
  margin-left: 300px;
  margin-top: 10px;
  display: block;
  color: #017aaf;
  font-size: 22px;
  font-weight: 600;
}

.subscribe-body {
  margin-left: 300px;
  display: inline-block;
  font-size: 22px;
  font-weight: 400;
  max-width: 400px;
}

.subscribe-button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: 'proxima_nova_rgregular', arial, sans-serif;
  font-size: 16px;
  color: #fff;
  /* float: right; */
  display: inline-block;
  width: auto;
  height: 40px;
  line-height: 40px;
  background-color: #007cb0;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  background-image: url(https://img.medscape.com/pi/cme/mycme/icon-arrow-white-page-right.png);
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: auto;
  padding-right: 30px;
  padding-left: 20px;
}

.subscribe-button:hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  /*background-color:#007cb0;*/
}

.do-not-display-subscribe {
  display: none !important;
}

/* default to the largest size */

/* Max breakpoints */

/* Up to 1279 breakpoint */

@media (max-width:1279px) {
  .hp-anon .anon-graphic {
    background: url(/pi/global/icons/CME_Cards_tilted_large.png) right bottom no-repeat;
    background-size: auto 200px;
    background-position: right -150px bottom;
    box-shadow: inset 0px -7px 4px -4px rgba(0, 0, 0, 0.29);
  }
  .subscribe-wrapper {
    display: none;
  }
  .hp-anon p.description {
    font-size: 26px;
  }
  .dynamic-column-1-header,
  .dynamic-column-2-header {
    min-width: 49%;
  }
  .dynamic-column-3-header {
    display: none;
  }
  .resource-center-view-all {
    float: right;
    margin-top: -37px;
    background-color: white;
    width: 100px;
    line-height: 35px;
  }
  .cards li {
    width: auto;
  }
  .extra-wide-article.cards li {
    width: 95.5%;
    max-width: 100%;
  }
  .cards {
    max-width: 100%;
    width: 100%;
  }
  .cards .activityImage img {
    max-width: 100%;
    text-align: center;
  }
  #left {
    max-width: 97%;
    width: 100%;
    padding-right: 10px;
  }
  #resource-center .max-width-limiter,
  #edu {
    width: 100%;
  }
  #resource-center .max-width-limiter {
    white-space: nowrap;
    overflow: hidden;
  }
  .filterColumns {
    flex-wrap: no;
  }
  .dynamic-column-1.card:nth-child(n+10),
  .dynamic-column-2.card:nth-child(n+10),
  .dynamic-column-3.card:nth-child(n+10) {
    display: block;
  }
  .extra-wide-article .activityImage {
    max-width: 260px;
  }
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-1.card:nth-child(-n+1),
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-2.card:nth-child(-n+1),
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-1.card:nth-child(-n+2),
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-2.card:nth-child(-n+2),
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-1.card:nth-child(n+2),
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-2.card:nth-child(n+2),
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-1.card:nth-child(n+3),
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-2.card:nth-child(n+3),
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-3.card {
    display: none;
  }
}

/* Up to 1023 breakpoint */

@media (max-width:1023px) {
  .hp-anon .anon-graphic {
    background: url(/pi/global/icons/CME_Cards_tilted_Medium.png) right bottom no-repeat;
    background-size: auto 200px;
    background-position: right 0px bottom;
    box-shadow: inset 0px -7px 4px -4px rgba(0, 0, 0, 0.29);
  }
  .hp-anon.subscribe .anon-graphic {
    background: url(/pi/global/icons/PaperPlane_Medium.png) right bottom no-repeat;
    background-size: auto 100%;
  }
  .hp-anon.subscribe .section-container {
    min-height: 157px;
  }
  .hp-anon p.description {
    font-size: 20px;
    width: 100%;
    max-width: 350px;
    line-height: 1.2;
  }
  .hp-anon p.login-line {
    display: block;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .hp-anon a.sign-up {
    font-size: 16px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .hp-anon .section-title {
    padding: 0px;
  }
  #edu .bucket#filter .bucketHeader #refineButton {
    margin-top: -28px;
  }
  .activityData .credits-number {
    display: inline;
    margin-right: 5px;
  }
  .cards.extra-wide-article .activityImage {
    display: block;
  }
  .cards.extra-wide-article .activityTitle .activityImage {
    display: none;
  }
  .extra-wide-article .activityImage {
    max-width: auto;
  }
  .extra-wide-article .activityData {
    max-width: 100%;
  }
  .extra-wide-article .activityImage {
    float: none;
    max-width: 100%;
  }
  .extra-wide-article .activityImage img {
    max-width: 260px;
  }
  .filterCol#topics ::-webkit-scrollbar-thumb {
    height: 30px;
  }
  #edu .bucket#filter .filterCols .filterCol#topics {
    margin-right: 30px;
  }
  #edu .bucket#filter .filterCols .filterCol#credits {
    margin-right: 100px;
  }
  #edu .bucket#filter .filterCols .filterCol#format {
    margin-right: 100px;
  }
  #edu .bucket#filter .filterCols .filterCol#type {
    margin-right: 100px;
  }
  #edu .bucket#filter .bucketHeader #refineButton {
    /*padding:8px 10px 7px 10px;*/
    /*background-image: none;*/
  }
  #edu .bucket#filter .bucketHeader #refineButton.closed {
    /*background-image: none;*/
  }
  #edu .bucket#filter .bucketHeader h2 {
    font-size: 24px;
  }
  #filterContent {
    position: absolute;
    top: 0;
    background-color: #fff;
    display: block;
    width: 70%;
    background-color: white;
    float: right;
    margin-left: 30%;
    height: auto;
    z-index: 2006;
  }
  .filter-spacer {
    display: block;
    width: 45%;
    float: left;
  }
  .filterColumns {
    display: block;
  }
  #edu .bucket#filter .filterCols .filterCol#topics ul {
    height: 500px;
  }
  #edu .bucket#filter .filterCols {
    border-bottom: solid 4px #edf0f5;
  }
  .filter-top-section {
    width: auto;
    background-color: #3894a2;
    display: block;
    height: 55px;
    line-height: 55px;
    padding-left: 25px;
    color: #fff;
  }
  #modal-bg-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    display: inline;
    overflow: hidden;
    background-color: black;
    z-index: 999;
    opacity: 0.5;
  }
  .extra-wide-article.cards li {
    width: 93.5%;
    max-width: 100%;
  }
  .dynamic-column-2-header {
    display: none;
  }
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-1.card:nth-child(-n+1),
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-2.card,
  .extra-wide-featured-row-two .primary-card-section .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-1.card:nth-child(-n+2),
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-2.card,
  .extra-wide-featured-row-three .primary-card-section .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-1.card:nth-child(n+2),
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-2.card,
  .extra-wide-featured-row-two .pre-extra-wide .dynamic-column-3.card {
    display: none;
  }
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-1.card:nth-child(n+3),
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-2.card,
  .extra-wide-featured-row-three .pre-extra-wide .dynamic-column-3.card {
    display: none;
  }
}

/* 900px for extra wide article alignment */

@media (max-width:900px) {
  .extra-wide-article.cards li {
    width: 91.5%;
    max-width: 100%;
  }
}

/* Up to 768 breakpoint */

@media (max-width:767px) {
  .hp-anon .anon-graphic {
    background: url(/pi/global/icons/CME_Cards_tilted_Small_@2x.png) right bottom no-repeat;
    background-size: 50% auto;
  }
  .hp-anon.subscribe .anon-graphic {
    background: url(/pi/global/icons/PaperPlane_Small_@2x.png) right bottom no-repeat;
    background-size: 35% auto;
  }
  .hp-anon.subscribe .section-container {
    min-height: 157px;
  }
  .hp-anon p.description {
    font-size: 16px;
    width: 100%;
    line-height: 1.13;
  }
  .hp-anon .login-line {
    display: block;
    padding-bottom: 10px;
  }
  .hp-anon a.sign-up {
    font-size: 16px;
    padding: 10px 20px;
  }
  #saved-activities {
    display: none !important;
  }
  .saved-and-tracker {
    display: block;
    border-bottom: solid 4px #d2beb2;
    width: 100%;
    display: block;
    padding-bottom: 16px;
    margin-top: 20px;
  }
  .saved-and-tracker a {
    font-family: 'Roboto Condensed', arial, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    color: #333132;
    /*border-bottom:2px solid #8f8f8e;*/
  }
  .bp-one-saved {
    background-image: url(/pi/global/icons/saved-activity-branding-bar-red.svg);
    background-repeat: no-repeat;
    padding-left: 20px;
  }
  .bp-one-tracker {
    float: right;
    background-image: url(/pi/global/icons/activity-tracker-branding-bar-red.svg);
    background-repeat: no-repeat;
    padding-right: 20px;
    background-position: right;
    display: inline-block;
    height: 20px;
    line-height: 22px;
    margin-top: -2px;
  }
  .refine-topics-search {
    font-size: 16px;
  }
  a.cme-submenu-more,
  a.cme-submenu-more:visited {
    margin-right: -10px;
  }
  .cards {
    padding: 0px;
  }
  #edu .bucket#filter .filterCols .filterCol ul li {
    padding-bottom: 18px;
  }
  .filter-top-section {
    padding-left: 20px;
  }
  .filter-top-section-text {
    display: inline-block;
    line-height: 55px;
    height: 55px;
  }
  .filterCol#topics ::-webkit-scrollbar {
    display: none;
  }
  #edu .bucket#filter .filterCols .filterCol ul {
    margin-bottom: 60px;
  }
  #ads-pos-141 {
    display: none;
  }
  #edu .bucket#filter .filterButtons {
    bottom: 0px;
    position: fixed;
    text-align: center;
    width: 100%;
    background-color: #fff;
    padding-bottom: 20px;
    margin: 0px;
  }
  .total-results-refine-box {
    display: block;
    float: initial;
    width: 80%;
  }
  #edu .bucket#filter .filterButtons input[type="button"].cancel {
    margin-left: -20%;
    margin-right: 20%;
  }
  #edu .bucket#filter .filterButtons input[type="button"].apply {
    margin-left: -20%;
  }
  #edu .bucket#filter .bucketHeader {
    padding-bottom: 12px;
    overflow: hidden;
    display: flex;
    width: 100%;
  }
  #edu .bucket#filter .bucketHeader h2 {
    width: 100%;
  }
  #edu .bucket#filter .bucketHeader #refineButton {
    margin-top: 0px;
  }
  .extra-wide-article .activityData {
    max-width: 100%;
  }
  /*
	.extra-wide-article .activityImage img {
		max-width:100%;
	}
	*/
  #left {
    padding-right: 0px;
    margin-right: 0px;
    margin: 0 2% 0 1%;
    width: 98%;
    max-width: 100%;
  }
  #right {
    max-width: 100%;
  }
  .dynamic-column-1-header,
  .dynamic-column-2-header,
  .dynamic-column-3-header {
    margin-right: 0px;
  }
  #edu .bucket#filter .bucketHeader #refineButton {
    /*padding:8px 0px 7px 10px;*/
    width: 62px;
    background-position: right 10px center;
  }
  .filter-back-button {
    cursor: pointer;
    background-image: url(//img.medscapestatic.com/pi/global/icons/mobile-filters-left-arrow.png);
    background-repeat: no-repeat;
    background-size: 14px 24px;
    padding-left: 22px;
    display: inline-block;
    background-position: left 15px;
  }
  .filterCols {
    display: none;
  }
  #filterContent {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    display: block;
    width: 80%;
    background-color: white;
    float: right;
    margin-left: 0%;
    height: auto;
    z-index: 2006;
  }
  #mobile-filter-menu {
    display: block;
  }
  #mobile-filter-menu .menu-options {
    height: 19px;
    font-size: 14px;
    font-family: 'Roboto Condensed', arial, sans-serif;
    padding: 12px 0px 14px 18px;
    cursor: pointer;
    width: auto;
    border-bottom: solid 1px #edf0f5;
    background-image: url(//img.medscapestatic.com/pi/global/icons/filters-right-caret.png);
    background-repeat: no-repeat;
    background-size: 8px 15px;
    background-position: 95% center;
  }
  #filterContent.js-mobile-selection #mobile-filter-menu {
    display: none;
  }
  #filterContent.js-mobile-selection .filterCols {
    display: block;
  }
  .filterCol {
    display: none;
  }
  #filterContent.js-mobile-topics .filterCol#topics {
    display: block
  }
  #edu .bucket#filter #filterContent.js-mobile-topics .filterCols .filterCol#topics {
    width: auto;
    margin-right: 0px;
    margin: 0px;
    float: none;
  }
  #edu .bucket#filter #filterContent.js-mobile-topics .filterCols .filterCol#topics ul {
    height: auto;
  }
  #edu .bucket#filter .bucketContent.js-mobile-selection {
    overflow: scroll;
  }
  #filterContent.js-mobile-credits .filterCol#credits {
    display: block;
  }
  #filterContent.js-mobile-format .filterCol#format {
    display: block;
  }
  #filterContent.js-mobile-type .filterCol#type {
    display: block;
  }
  .filter-select-options {
    display: block;
  }
  .extra-wide-article.cards {
    width: auto;
  }
  .extra-wide-article.cards li {
    width: 100%;
    max-width: 100%;
  }
  .extra-wide-article .activityImage {
    max-width: 100%;
  }
  #right {
    margin-left: 10px;
    margin-right: 10px;
  }
  #content-wrap {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  #tracker {
    display: none;
  }
  #right {
    width: auto;
  }
  #pre-footer #rectangle {
    display: none;
  }
  #featuredTopics,
  #belowTrackerSection,
  #eventCalloutsRight,
  .multispecialty-hp {
    width: auto;
  }
  #square {
    text-align: center;
  }
  #square img {
    width: auto;
  }
  #pre-footer ul.pageNumbers li {
    margin: 0 10px;
  }
}

/* Min Breakpoints */

/* at least 768 breakpoint */

@media (min-width:768px) {
  .ous-ul .dynamic-column-1.card:nth-child(n+7),
  .ous-ul .dynamic-column-2.card:nth-child(n+7),
  .ous-ul .dynamic-column-3.card:nth-child(n+7) {
    display: none;
  }
  .dynamic-column-1.card:nth-child(n+19) {
    display: none;
  }
}

/* at least 1024 breakpoint */

@media (min-width:1024px) {
  .ous-ul .dynamic-column-1.card:nth-child(n+4),
  .ous-ul .dynamic-column-2.card:nth-child(n+4),
  .ous-ul .dynamic-column-3.card:nth-child(n+4) {
    display: none;
  }
  #edu .bucket#filter .filterCols .filterCol#topics ul {
    max-height: 180px;
  }
  .dynamic-column-1.card:nth-child(n+10),
  .dynamic-column-2.card:nth-child(n+10) {
    display: none;
  }
}

/* at least 1280 breakpoint */

@media (min-width:1280px) {
  .ous-ul .dynamic-column-1.card:nth-child(n+3),
  .ous-ul .dynamic-column-2.card:nth-child(n+3),
  .ous-ul .dynamic-column-3.card:nth-child(n+3) {
    display: none;
  }
  .dynamic-column-1.card:nth-child(n+7),
  .dynamic-column-2.card:nth-child(n+7),
  .dynamic-column-3.card:nth-child(n+7) {
    display: none;
  }
  .small-img .teaser img {
    display: block;
  }
  .small-img .activityImage {
    display: none;
  }
}

@media (min-width:1310px) {
  #featuredTopics,
  #belowTrackerSection,
  #eventCalloutsRight,
  #tracker,
  .multispecialty-hp {
    width: 300px;
  }
}

/* global override */

#adtagheader {
  margin: 0px;
  padding: 0px;
  display: none;
}

#adtagfooter,
.page-footer {
  margin-top: 0px;
}

.page-header {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#featuredTopics ul li a .title:hover,
#staff li a:hover {
  text-decoration: underline;
}

/*********************************************/

/** Personalized Education Targeting Widget **/

/*********************************************/

@import "../pet-widget/pet-widget.scss";
/*********************************************/

/** Layer that appears behind menu **/

/*********************************************/

#whiteoutlayer,
.whiteout-header-dropdown,
.whiteout-transparent {
  background-color: rgb(255, 255, 255);
  opacity: 0.40;
  /* Safari, Opera */
  -moz-opacity: 0.40;
  /* FireFox */
  filter: alpha(opacity=40);
  /* IE */
  width: 100%;
  position: absolute;
  z-index: 496;
  margin: 0 auto;
  min-height: 100%;
  height: auto;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Hack for IE, Reset to 100% height */

* html #whiteoutlayer {
  height: 100%;
}

/* End IE Hack */

.whiteout-transparent {
  position: fixed;
  background: transparent;
  -webkit-opacity: 0;
  opacity: 0;
  -webkit-visibility: hidden;
  visibility: hidden;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
}

.whiteout-header-dropdown {
  background: #000000;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -moz-opacity: 0.80;
  filter: alpha(opacity=80);
  transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
  position: fixed;
}

.dropdown-view .whiteout-header-dropdown {
  opacity: 0.8;
  visibility: visible;
  transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
}

#right {
  width: calc(100% - 20px);
}

.apply.homepage-button {
  cursor: default;
}

.apply.homepage-button.enabled {
  cursor: pointer;
}

#edu .bucket#filter .filterButtons input[type="button"].apply.homepage-button {
  cursor: default;
}



#filter .all-filter-option {
	display:list-item
}
#filter .untargeted-filter-option, #filter .targeted-filter-option {
	display:none;
}

#filter.only-show-us-filters .untargeted-filter-option  {
	display:list-item;
}
#filter.only-show-us-filters .all-filter-option, #filter.only-show-us-filters .targeted-filter-option {
	display:none;
}

#filter.only-show-ous-filters .targeted-filter-option  {
	display:list-item;
}
#filter.only-show-ous-filters .all-filter-option, #filter.only-show-ous-filters .untargeted-filter-option {
	display:none;
}



#filter .regional-tab-us {
	display:none !important;
}

/* custom banner styles start */
#bodypadding [class$="-banner-wrapper"] {
	float:right;
}
#bodypadding .hp-anon .anon-graphic {
	font-family: 'Roboto Condensed';
    background-repeat: no-repeat;
    background-size: auto 200px;
    background-position: center;
    min-height: 200px;
}
.medscape-edu-logo, .medscape-liveConference-logo{	
    width: 200px;
    height: 50px;
    background-repeat: no-repeat;
	background-size: auto;
    margin: auto;
}
.medscape-liveConference-logo{
    background-image: url(/pi/global/icons/dermatology/MedscapeLIVEConference_200x50.png);
}
.medscape-edu-logo {
    background-image: url(/pi/global/icons/covid-medscape-edu.png);
}
#bodypadding [class$="-banner-wrapper"] a{
	text-decoration: none;
}
/* custom banner styles end */
#petWrap {
	position: fixed;
	bottom: 30px;
	left: -999px;
	font-size: 1.1em;
	transition:left 2s ease-out;
}
#pet {
	width: 100%;
	max-width: 410px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    z-index: 500;
}
#pet .pet-header {
	background-color: #751400;
	padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#pet .pet-header h3 {
    font-family: Roboto Condensed,arial,sans-serif;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #ffffff;
	margin: 0;
	padding: 0;
}
#pet .cmetag {
	display: none;
}
#pet a.pet-close {
	width: 14px;
	height: 14px;
    display: inline-block;
    background-image: url("//img.medscape.com/pi/global/icons/pet-close.svg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
    text-decoration: none;
    text-indent: -99999px;
}
#pet .pet-body {
    padding: 20px;
    background: #fff;
}
#pet .pet-body ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
	flex-flow: row nowrap;
}
#pet .pet-body ul li {
	margin: 0;
	padding: 0;
	text-align: left;
}
#pet .pet-body ul li:nth-child(1){
	flex: 1;
}
#pet .pet-body ul li:nth-child(2){
	width: 140px;
	height: 105px;
    margin-left: 8px;
}
#pet .pet-body .featimg {
	margin: 0;
    width: 100%;
}
#pet .pet-body ul li a.title {
    font-family: 'proxima_nova_ltsemibold', arial, sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #000000;
}
#pet .pet-body .teaser {
	display: block;
	font-family: proxima_nova_ltlight,sans-serif;
	font-size: 15px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000000;
	margin: 6px 0 0;
	padding: 0;
}
#pet .pet-body .byline {
	display: none;
}
#pet .bucketFooter {
	display: none;
}
#pet .featimg {
    margin: 2px 5px 0 0;
    padding: 0;
    width: 72px;
    position: relative;
    float: left;
    clear: both;
	margin-top:3px;
}


// .petwrap {
//   width: 373px;
//   position: fixed;
//   bottom: 30px;
//   font-size: 1.1em;
//   overflow:hidden;
//   left: -50%;
//   background-color: #ffffff;
//   -webkit-box-shadow: 0 0 14px 0 rgba(67, 93, 106, 0.6);
//   box-shadow: 0 0 14px 0 rgba(67, 93, 106, 0.6);
//   -webkit-transition: left 2s ease-out;
//   transition: left 2s ease-out;
//     .pet {
//       z-index: 500;
//       padding: 21px 24px 23px;
//       .pet-header {
//         max-height: 44px;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-orient: horizontal;
//         -webkit-box-direction: normal;
//         -ms-flex-flow: row nowrap;
//         flex-flow: row nowrap;
//         -webkit-box-pack: justify;
//         -ms-flex-pack: justify;
//         justify-content: space-between;
//         padding-bottom: 5px;
//         border-bottom: 3px solid #333132;
//         h3 {
//           font-family: 'proxima_nova_rgbold';
//           font-size: 16px;
//           text-align: left;
//           color: #000;
//           font-weight: bold;
//           padding: 0;
//         }
//         .pet-close {
//           color: #fff;
//           text-decoration: none;
//           width: 15px;
//           height: 15px;
//           text-indent: -9999px;
//           display: block;
//           overflow: hidden;
//           background-image: url(https://img.medscapestatic.com/pi/global/icons/pl-close.svg);
//           background-size: contain;
//           background-repeat: no-repeat;
//           margin-top: 3px;
//         }
//       } //.pet-header
//       .pet-body {
//         padding-top: 17px;
//         ul {
//           margin: 0;
//           padding: 0;
//           display: -webkit-box;
//           display: -ms-flexbox;
//           display: flex;
//           -webkit-box-orient: horizontal;
//           -webkit-box-direction: normal;
//           -ms-flex-flow: row nowrap;
//           flex-flow: row nowrap;
//           li {
//             list-style: none;
//             &:nth-child(2) {
//               margin-left: 21px;
//               a {
//                 overflow: hidden;
//                 width: 81px;
//                 display: block;
//               }
//               img.featimg {
//                 max-width: 81px;
//               }
//             }
//             .cmetag {
//               display: none;
//             }
//             a.title {
//               font-family: 'Roboto Condensed';
//               font-size: 20px;
//               font-weight: bold;
//               line-height: 1.1;
//               letter-spacing: normal;
//               text-align: left;
//               color: #333132;
//               text-decoration: none;
//               display: block;
//               margin-bottom: 12px;
//               &:hover {
//                 text-decoration: underline;
//               }
//             }
//             span.teaser {
//               font-family: 'proxima_nova_ltlight';
//               font-size: 16px;
//               font-weight: 300;
//               line-height: 1.25;
//               text-align: left;
//               color: #222222;
//               display: block;
//             }
//           }
//         }
//       } //.pet-body
//     }
//   }
  
  